export default function({ store, commit, $axios, app, env, error, redirect }) {
  $axios.setHeader('Content-Type', 'application/json')

  $axios.onRequest((config) => {
    if (env.envName === 'master') {
      config.baseURL = env.apiProd
    } else if (env.envName === 'staging') {
      config.baseURL = env.apiStage
    } else if (env.envName === 'develop') {
      config.baseURL = env.apiDev
    } else if (env.envName === 'localhost') {
      config.baseURL = env.apiLocalhost
    }

    if (config.customBaseUrl) {
      config.baseURL = config.customBaseUrl
    }

    if (store.state.auth.token) {
      config.headers.common.authorization = 'Bearer ' + store.state.auth.token
    }

    // Set cancelToken source to make it possible to cancel requests on route navigation
    const cancelSource = $axios.CancelToken.source()
    config.cancelToken = cancelSource.token
    store.commit('axioscancelrequests/setRequestsCancelToken', cancelSource)

    console.log('🚀 Making request to ' + config.url)
  })

  // Cancel all requests every time the route changes
  app.router.beforeEach(async (to, from, next) => {
    await store.dispatch('axioscancelrequests/cancelPendingRequests')
    next()
  })

  $axios.onResponse((response) => {
    // console.log('🗣 Response: ', response.data)
  })

  $axios.onError((axiosError) => {
    const code = parseInt(axiosError.response && axiosError.response.status)
    if (code) {
      console.log('🥊 Error with request code: ', code)
    }

    if (code === 401) {
      console.log('Clear token')
      store
        .dispatch('auth/clearToken')
        .catch((err) => {
          console.log(err)
          error({ statusCode: 401, message: 'Invalid login' })
        })
        .then(() => {
          return redirect('/backend/public/admin')
        })
    }

    if ($axios.isCancel(axiosError)) {
      console.log(axiosError.message)
      return Promise.reject(axiosError)
    }
  })
}
