import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _57fb20af = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6eb67a6 = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _37bd19ad = () => interopDefault(import('../pages/_lang/_productgroup/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/index" */))
const _250555ed = () => interopDefault(import('../pages/_lang/_productgroup/combination/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/combination/index" */))
const _a5c407ee = () => interopDefault(import('../pages/_lang/_productgroup/literature/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/literature/index" */))
const _b39c4612 = () => interopDefault(import('../pages/_lang/_productgroup/_type/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/_type/index" */))
const _68fe72db = () => interopDefault(import('../pages/_lang/_productgroup/_type/_subject/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/_type/_subject/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _57fb20af,
    name: "index"
  }, {
    path: "/:lang",
    component: _f6eb67a6,
    name: "lang"
  }, {
    path: "/:lang/:productgroup",
    component: _37bd19ad,
    name: "lang-productgroup"
  }, {
    path: "/:lang/:productgroup/combination",
    component: _250555ed,
    name: "lang-productgroup-combination"
  }, {
    path: "/:lang/:productgroup/literature",
    component: _a5c407ee,
    name: "lang-productgroup-literature"
  }, {
    path: "/:lang/:productgroup/:type",
    component: _b39c4612,
    name: "lang-productgroup-type"
  }, {
    path: "/:lang/:productgroup/:type/:subject",
    component: _68fe72db,
    name: "lang-productgroup-type-subject"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
